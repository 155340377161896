import { Flex, Heading, Text } from '@chakra-ui/react';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React from 'react';

const options = {
	renderText: (text) => {
		return text.split('\n').reduce((children, textSegment, index) => {
			return [ ...children, index > 0 && <br key={index} />, textSegment ];
		}, []);
	}
};

function FinePrintPage({ pageContext: { title, body } }) {
	const richTextBody = renderRichText(body, options);
	return (
		<Flex w="100%" flexDir="column">
			<Heading mt={16}>{title}</Heading>
			<Text mt={6} mb={{ base: 16, lg: 32 }}>
				{richTextBody}
			</Text>
		</Flex>
	);
}

export default FinePrintPage;
